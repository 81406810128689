@font-face {
  font-family: myFirstFont;
  src: url("../fonts/NunitoSans-Regular.ttf");
}

body {
  font-family: myFirstFont;
}

.main-wrapper{  
  display: flex;        
  height: auto;
  justify-content: center;
  /* padding: "0.5rem 4rem"; */
  position: fixed;
  bottom: 30px;
  right: 40px;
}

.main-box {
  width: 400px;
  border-radius: 26px;
  box-shadow: 0px 0px 49px rgba(0, 0, 0, 0.1);
}

.header {
  background: linear-gradient(90deg, #56ccf2 0%, #2f80ed 100%);
  border-radius: 25px 25px 0px 0px;
  padding: 10px 20px;
  display: flex;
  align-items: center;
}

.header-text {
  color: #ffffff;
  margin: 0;
  font-family: myFirstFont;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  display: inline;
}

.header-avatar {
  height: 40px;
  width: 40px;
  margin-right: 10px;
}

.header-avatar-hand {
  height: 25px;
  width: 25px;
  margin-left: 5px;
}

.header-avatar-ellipsis {
  height: 20px;
  width: auto;
  margin-left: auto;
}

.header-avatar-arrow {
  height: 8px;
  width: auto;
  margin-left: 25px;
}

.header-online {
  height: 80px;
  background-image: url("../images/header-bg.png");
  background-repeat: no-repeat;
}

.header-online-dot {
  background: linear-gradient(141.39deg, #63ff72 -14.09%, #14e097 72.41%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  margin-right: 5px;
  font-size: 30px;
}

.header-online-text {
  display: flex;
  align-items: center;
  color: #ffffff;
  border-radius: 20px;
  margin: 0;
  padding: 8px 20px 15px 20px;
  font-family: myFirstFont;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
}

.message-container {
  overflow-y: scroll;
  margin-top: -12px;
  max-height: 345px;
  padding: 0px 15px 15px 15px;
}
.message-divider {
  border: 0.8px solid #c4c4c4;
}

.message-text {
  max-width: 60%;
  color: #000000;
  background-color: #f1f1f1;
  box-shadow: 0px 0px 6px rgba(160, 160, 160, 0.16);
  border-radius: 0px 10px 10px 10px;
  margin: 0;
  padding: 12px 20px;
  font-family: myFirstFont;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}
.message-left {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 10px;
}
.pointer {
  cursor: pointer;
}

.message-right {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}
.message-heading{
  width: 90%;
  color: #ffffff;
  border: none;
  background: linear-gradient(90deg, #56ccf2 0%, #2f80ed 100%);
  box-shadow: 0px 0px 6px rgba(160, 160, 160, 0.16);
  border-radius: 20px;
  margin: 0;
  padding: 12px 20px;
  font-family: myFirstFont;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}

/* Button Effect */
.button {
  width: 70%;
  background: 
    linear-gradient(#FFF,#FFF) padding-box left, 
    linear-gradient(to right, #56ccf2 0%, #2f80ed 100%) border-box;
  background-size:100% 100%,auto;
  background-repeat:no-repeat;
  border: 2px solid transparent;
  color: #3183ED;
  border-radius: 8px;
  margin: 10px auto 0;
  font-weight: 600;
  font-size: 16px;
  padding: 15px 20px;
  transition: all .5s ease;
  display: block;
  }
  
.button:hover{
  background-size:0 100%,auto;
  color: #FFF;
  cursor: pointer;
}


.btn-center {
  display: flex;
  justify-content: center;
  /* margin-bottom: 10px; */
}
.dedcription-btn {
  min-width: 90%;
  height: 55px;
  position: relative;
  display: inline-block;
  border-radius: 35px;
  background-color: #f1f1f1;
  color: #000000;
  text-align: center;
  font-family: myFirstFont;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  padding: 9px 0;
  transition: all 0.3s;
  /* padding-right: 40px; */
  margin: 10px 5px;
  border: none;
  /* box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.06); */
}
.dedcription-btn:hover {
  color: #fff!important;
}
.dedcription-btn .btn-icon {
  background : linear-gradient(90deg, #56ccf2 0%, #2f80ed 100%);
  min-width: 30%;
  height: 55px;
  float: right;
  position: absolute;
  border-radius: 35px 35px 35px 0px;
  right: 0px;
  top: 0px;
  transition: all 0.3s;
}
.name-descripeion {
  position: relative;
  z-index: 9999;
}
.btn-icon::after {
  content: "";
  width: 0;
  height: 0;
  border-top: 55px solid #f1f1f1;
  border-right: 50px solid transparent;
  position: absolute;
  top: 0px;
  left: 0px;
}

.btn-icon img {
  position: absolute;
  right: 25px;
  top: 15px;
  height: 55px;
  padding-bottom: 100px;
  margin: -15px;
  padding-right: 5px;
}
.dedcription-btn:hover .btn-icon {
  width: 100%;
  border-radius: 35px;
}
.dedcription-btn:hover .btn-icon::after {
  display: none;
  opacity: 0.3s;
}

/* end button */

.message-text-time {
  color: gray;
  margin: 0;
  padding: 0px 5px;
  font-family: myFirstFont;
  font-style: normal;
  font-weight: 400;
  font-size: 9px;
  display: flex;
  margin-bottom: 3px;
}

.flex-left {
  justify-content: flex-start;
}

.flex-right {
  justify-content: flex-end;
}

.main-message {
  display: flex;
  height: 60px;
}

.message-input-container {
  width: 90%;
  display: flex;
  align-items: center;
}

.message-send-container {
  width: 10%;
  display: flex;
  align-items: center;
}

*:focus {
  outline: none;
}

.message-input {
  width: 95%;
  padding: 5px 20px;
  border: none;
  font-size: 16px;
}

.message-send-icon {
  height: 70px;
  margin-right: -50px;
}

.footer {
  border-radius: 0px 0px 25px 25px;
  padding: 15px 20px;
  display: flex;
  align-items: center;
}

.footer-text {
  color: #999999;
  margin: 0;
  font-family: myFirstFont;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  display: inline;
  margin-left: auto;
}

.footer-avatar-px {
  height: 25px;
  width: auto;
  margin-left: 8px;
}

.footer-avatar-patient {
  height: 25px;
  width: auto;
  margin-left: 8px;
}

.avatar-chat-container {
  height: 25px;
  width: auto;
}

.avatar-chat {
  height: 100px;
  /* width: 150px; */
  width: auto;
}

/* Dot Pulse Effect */
.dot-pulse {
  position: relative;
  left: -9999px;
  width: 8px;
  height: 8px;
  border-radius: 5px;
  background-color: #5e5e5e;
  color: #5e5e5e;
  box-shadow: 9999px 0 0 -5px;
  animation: dot-pulse 1.5s infinite linear;
  animation-delay: 0.25s;
}
.dot-pulse::before, .dot-pulse::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  width: 8px;
  height: 8px;
  border-radius: 5px;
  background-color: #5e5e5e;
  color: #5e5e5e;
}
.dot-pulse::before {
  box-shadow: 9984px 0 0 -5px;
  animation: dot-pulse-before 1.5s infinite linear;
  animation-delay: 0s;
}
.dot-pulse::after {
  box-shadow: 10014px 0 0 -5px;
  animation: dot-pulse-after 1.5s infinite linear;
  animation-delay: 0.5s;
}

@keyframes dot-pulse-before {
  0% {
    box-shadow: 9984px 0 0 -5px;
  }
  30% {
    box-shadow: 9984px 0 0 2px;
  }
  60%, 100% {
    box-shadow: 9984px 0 0 -5px;
  }
}
@keyframes dot-pulse {
  0% {
    box-shadow: 9999px 0 0 -5px;
  }
  30% {
    box-shadow: 9999px 0 0 2px;
  }
  60%, 100% {
    box-shadow: 9999px 0 0 -5px;
  }
}
@keyframes dot-pulse-after {
  0% {
    box-shadow: 10014px 0 0 -5px;
  }
  30% {
    box-shadow: 10014px 0 0 2px;
  }
  60%, 100% {
    box-shadow: 10014px 0 0 -5px;
  }
}

/* Scrollbar */
/*  width */
.message-container::-webkit-scrollbar {
  width: 8px;
}

 /* Track  */
.message-container::-webkit-scrollbar-track {
  border-radius: 10px;
}

 /* Handle  */
.message-container::-webkit-scrollbar-thumb {
  /* background: linear-gradient(90deg, #56ccf2 0%, #2f80ed 100%);  */
  background-color: #b6b6b6;
  border-radius: 10px;
}

/* Handle on hover*/
.message-container::-webkit-scrollbar-thumb:hover {
  /* background: linear-gradient(90deg, #56ccf2 0%, #2f80ed 100%);  */
  background-color: #b6b6b6;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .main-wrapper{
    width: 100%;
    bottom: 0;
    right: 20px;
    top: 0px;
    left: 0;
  }
  .main-box{
    box-shadow: none;
    border-radius: 0 0 0 0;
  }
  .header{
    border-radius: 0 0 0 0;
  }
  .message-send-icon{
    margin-right: 0;
  }
  .main-message{
    padding: 6px;
    justify-content: space-between;
  }
  .message-input-container{
    width: auto;
  }
  .message-send-container{
    width: auto;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .main-wrapper{
    bottom: 22px;
    right: 30px;
  }
  .main-box {
    width: 370px;
  }
  .message-container{
    max-height: 272px;
  }
  .main-message{
    height: 48px;
  }
} 

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .main-box {
    width: 370px;
  }
  .message-container{
    max-height: 350px;
  }
} 

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 1024px) {
  .main-box {
    width: 370px;
  }
  .message-container{
    max-height: 272px;
  }
} 

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1280px) {
  .main-box {
    width: 350px;
  }
  .message-container{
    max-height: 272px;
  }
} 

@media only screen and (min-width: 1990px) {
  .main-box {
    width: 350px;
  }
  .message-container{
    max-height: 350px;
  }
} 
